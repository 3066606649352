import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../components/DataTable";


function TeamManagment() {
  return (
    <>
      <div className="manage_employee">
        <div className="add_button">
          <a href="">اضافة فريق</a>
        </div>

        <Grid container spacing={8} className="algin-center">
          <Grid xs={9}>
            <div className="table_show table_style">
              <h5>قائمة بجميع الموظفين</h5>
              <a href="" className="showall">
                عرض الكل
              </a>
              <DataGridDemo />
            </div>
          </Grid>
          <Grid xs={3}>
            <div className="options_buttons">
              <ul className="list-unstyled">
                <li>
                  <a href="">قائمة المشرفين</a>
                </li>
                <li>
                  <a href="">قائمة الفنيين</a>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default TeamManagment;
