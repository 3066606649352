import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { sendAuth } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

function Login() {
  const simpleValidator = useRef(new SimpleReactValidator());
  const role_id = useSelector((state) => state.authSlice.role_id);
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const [creditial, setCreditional] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    localStorage.setItem("role_id", "");
    localStorage.setItem("token", "");
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setCreditional((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const senToLogin = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      dispatch(sendAuth(creditial))
        .unwrap()
        .then((data) => {
          localStorage.setItem("role_id", data.role_id);
          localStorage.setItem("token", data.token);
          if (data.role_id === 1) {
            toast.success("مرحبا");
            setTimeout(() => {
              setLoading(false);
              navigate("/superadmin");
              window.location.reload();
            }, 1000);
          }else if (data.role_id === 2) {
            toast.success(`مرحبا`);
            setTimeout(() => {
              setLoading(false);
              navigate("/pm");
              window.location.reload();
            }, 1000);
          }else if (data.role_id === 3) {
            toast.success(`مرحبا`);
            setTimeout(() => {
              setLoading(false);
              navigate("/dh");
              window.location.reload();
            }, 1000);
          }else if (data.role_id === 4) {
            toast.success(`مرحبا`);
            setTimeout(() => {
              setLoading(false);
              navigate("/tm");
              window.location.reload();
            }, 1000);
          }else if (data.role_id === 5) {
            toast.error(`ليس لك الحق للدخول لهذه الصفحه`)
            setTimeout(() => {
              setLoading(false);
              navigate("/");
              window.location.reload();
            }, 1000);
          }
          else if (data.role_id === 6) {
            toast.success(`مرحبا`);
            setTimeout(() => {
              setLoading(false);
              navigate("/monitor");
              window.location.reload();
            }, 1000);
          }
        })
        .catch((err) => {
          // console.log(err.message);
          setLoading(false);
          toast.error(err.message);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="login_page">
          <img
            className="wavelogin"
            src={`${process.env.PUBLIC_URL}/images/wavelogin.svg`}
            alt=""
          />
          <Container maxWidth="xxl">
            <div className="login_form">
              <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
              </div>
              <form action="" onSubmit={senToLogin}>
                <div className="form-group">
                  <label htmlFor="">البريد الالكتروني</label>
                  <input
                    name="email"
                    value={creditial.email}
                    type="text"
                    className="form-control"
                    placeholder="example@example.com"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("email")
                    }
                  />
                  <div className="error">
                    {simpleValidator.current.message(
                      "email",
                      creditial.email,
                      "required"
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">كلمه المرور</label>
                  <input
                    name="password"
                    value={creditial.password}
                    type="password"
                    className="form-control"
                    placeholder="**********"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("password")
                    }
                  />
                  <div className="error">
                    {simpleValidator.current.message(
                      "password",
                      creditial.password,
                      "required"
                    )}
                  </div>
                </div>
                <div className="btn_submit">
                  <button className="btn">تسجيل الدخول</button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default Login;
