import React from 'react'

function ReturnBtn() {
  return (
    <div className="return_btn">
      <a href="">
        <span>رجوع</span>
        <i className="fas fa-long-arrow-alt-left"></i>
      </a>
    </div>
  )
}

export default ReturnBtn