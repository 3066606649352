import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import TablePaginaiton from "../components/TablePaginaiton";
import { getNotifications } from "../store/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

function Notifications() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notinumber, setnotinumber] = useState(0);
  const [page, setPage] = useState(1);
  const [totlepages, setTotlepages] = useState(0);
  const notifications = useSelector(
    (state) => state.notificationsSlice.notifications
  );
  useEffect(() => {
    setLoading(true);
    dispatch(getNotifications(page))
      .unwrap()
      .then((data) => {
        console.log(data);
        setLoading(false);
        setnotinumber(data.data.length);
        setTotlepages(data?.pagination?.last_page)
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  }, [dispatch, page]);

  const handlepageChange = (event, value) => {
    setPage(value);
    // console.log(value);
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="notifications custom_pagination_table2">
          <div className="notification_component">
            <h5>الاشعارات</h5>
            <ul className="norilist">
              {notifications.map((noti) => (
                <li key={noti.id}>
                  <p>{noti.content}</p>
                  <span className="data">{noti?.timestamp?.split(" ")[0]}</span>
                </li>
              ))}
            </ul>
          </div>
          <TablePaginaiton
            totlepages={totlepages}
            page={page}
            setPage={setPage}
            handlepageChange={handlepageChange}
            className="custom_pagination_table"
          />
        </div>
      )}
    </>
  );
}

export default Notifications;
