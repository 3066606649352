import React, { useRef, useState, useEffect } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas } from "../../store/areasSlice";
import { addfileDitricts } from "../../store/districtsSlice";
import { AddBulkbuildings } from "../../store/buildingSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

function AddBulkBuilding() {
  const dispatch = useDispatch();
  const [loading, setLoding] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const navigate = useNavigate();
  const [excel_file, setExcelFile] = useState("");
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  }else if (role_id == "3") {
    roleName = "dh";
  }


  const handelUploadPicture = (e) => {
    const { name, files } = e.target;

    // Assuming you are updating the state with the selected file

    setExcelFile(files[0]);
  };

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      const formData = new FormData();
      formData.append("excel_file", excel_file);
      setLoding(true);
      dispatch(AddBulkbuildings(formData))
        .unwrap()
        .then((data) => {
          setLoding(false);
          toast.success("تم الاضافه بنجاح");
          navigate(`/${roleName}/building-list`);
        })
        .catch((error) => {
          setLoding(false);
          toast.error(`${error.message}`);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>إضافة لوحات جديده</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form onSubmit={saveData} action="">
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">ملف المباني:</label>
                  <input
                    type="file"
                    name="excel_file"
                    onChange={handelUploadPicture}
                    className="form-control"
                    placeholder="يرجي ادخال رقم الحي الجديد"
                    accept=".xls, .xlsx"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("excel_file")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "excel_file",
                    excel_file,
                    "required"
                  )}
                </div>
              </div>

              <div className="btn_submit">
                <button className="btn_style" type="submit">
                  اضافة
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default AddBulkBuilding;
