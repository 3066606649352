import React from "react";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Widgets } from "@mui/icons-material";
ChartJS.register(zoomPlugin);
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "الفرق التي قامت بالتركيب",
    },
  },
};

function BarChart({ datesArray, implementedArray, height }) {
  const labels = datesArray;

  const data = {
    labels,
    datasets: [
      {
        label: "مجموعه",
        data: implementedArray,
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return <Bar options={options} data={data} height={height} />;
}

export default BarChart;
