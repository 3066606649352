import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyIcon from "@mui/icons-material/Key";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/profileSlice";
import { getNotifications } from "../store/notificationSlice";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { userLogout } from "../store/superadmin/usersSlice";

function AdSideNav() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usersprofile = useSelector(
    (state) => state.userProfileSlice.usersprofile
  );
  const [userdata, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  
  const signOut = () => {
    setLoading(true);
    dispatch(userLogout()).then((data) => {
      localStorage.setItem("role_id", "");
      localStorage.setItem("token", "");
      toast.success("تم تسجيل الخروج بنجاح");
      setLoading(false);
      navigate("/");
    });

  };

  useEffect(() => {
    dispatch(getProfile())
      .unwrap()
      .then((data) => {
        setLoading(false);
        // console.log(data);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <>
      <div className="side-nav">
        {loading ? (
          <Loading open={true} />
        ) : (
          <div className="person-info">
            <div className="card-img">
              <div className="img-parent">
                <img
                  src={`${process.env.PUBLIC_URL}${usersprofile.profile_image}`}
                  alt=""
                />
              </div>
            </div>
            <div className="card-body">
              <h5>{usersprofile.name}</h5>
              <p>{usersprofile.role_name_ar}</p>
            </div>
          </div>
        )}
        <div className="side_links">
          <ul>
            <li>
              <NavLink to="/superadmin" end>
                <span className="icon-home-icon"></span>
                <span>الصفحة الرئيسية</span>
              </NavLink>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-emplyee"></span>
                    <span>اداره الموظفين</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/superadmin/all-users">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع الموظفين
                    </NavLink>
                    <NavLink to="/superadmin/add-new-user/pm">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه مدير
                      مشروع
                    </NavLink>
                    <NavLink to="/superadmin/add-new-user/dh">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه رئيس
                      قسم
                    </NavLink>
                    <NavLink to="/superadmin/add-new-user/monitor">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه متابع
                    </NavLink>
                    <NavLink to="/superadmin/add-new-team">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه فريق
                    </NavLink>
                    <NavLink to="/superadmin/add-new-user/tm">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه عضو
                      فريق
                    </NavLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-emplyee"></span>
                    <span>اداره المساحات</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/superadmin/arealist">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع المساحات
                    </NavLink>
                    {/* <NavLink to="/superadmin/superadminnewara">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه منطقه
                    </NavLink> */}
                    {/* <NavLink to="/superadmin/assign-area">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>توزيع منطقه
                    </NavLink> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-paint2"></span>
                    <span>اداره المناطق</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/superadmin/district-list">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع المناطق
                    </NavLink>
                    <NavLink to="/superadmin/add-ditrict-kml">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه مناطق
                      جديده
                    </NavLink>
                    {/* <NavLink to="/superadmin/add-new-ditrict">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافة حي
                    </NavLink> */}
                    <NavLink to="/superadmin/assign-area">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>توزيع منطقه على رئيس قسم
                    </NavLink>
                    <NavLink to="/superadmin/assign-ditrict">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>توزيع منطقه
                      على فريق
                    </NavLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <span className="icon-paint2"></span>
                    <span>اداره اللوحات</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <NavLink to="/superadmin/building-list">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع اللوحات
                    </NavLink>
                    <NavLink to="/superadmin/all-pins">
                      <PeopleOutlinedIcon></PeopleOutlinedIcon>جميع النقاط
                    </NavLink>
                    <NavLink to="/superadmin/add-bulk-buildings">
                      <PersonAddOutlinedIcon></PersonAddOutlinedIcon>اضافه لوحات
                      جديده
                    </NavLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </li>
            <li>
              <NavLink to="/superadmin/settings">
                <span className="icon-settings"></span>
                <span>الاعدادات</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/superadmin/rest-password">
                <span>
                  <i className="fas fa-lock"></i>
                </span>
                <span>تغيير كلمه السر</span>
              </NavLink>
            </li>
            <li className="signout_btn">
              <a onClick={signOut}>
                <span>
                  <LogoutOutlinedIcon></LogoutOutlinedIcon>
                </span>
                <span>تسجيل الخروج</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default AdSideNav;
