import React from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

function MobileIcon({openSideMenue}) {
  return (
    <div className="menuIcon" onClick={openSideMenue}>
      <MenuOutlinedIcon></MenuOutlinedIcon>
    </div>
  );
}

export default MobileIcon;
