import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}

export const getPins = createAsyncThunk(
  "pins/fetshuserpins",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/points`, {
        headers,
      });
      const data = await res.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const pinsSlice = createSlice({
  name: "pins",
  initialState: {
    isLoading: true,
    pins: [],
  },
  reducers: {},
  extraReducers: {
    [getPins.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPins.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.pins = action.payload.data;
    },
    [getPins.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default pinsSlice.reducer;