import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
}else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}

export const getCities = createAsyncThunk(
  "cities/get-cities",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/cities`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const allCities = createSlice({
  name: "cities",
  initialState: { isLoading: true, cities: [] },
  reducers: {},
  extraReducers: {
    [getCities.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCities.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.cities = action.payload;
    },
    [getCities.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default allCities.reducer;