import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";



const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
}else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "6") {
  roleName = "monitor";
}


export const getPmUsers = createAsyncThunk(
  "pmusers/fetshpmusers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user?role_id=2`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDhUsers = createAsyncThunk(
  "dhusers/fetshdhusers",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user?role_id=3`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getAllDhMemeber = createAsyncThunk(
  "dhusers/fetsallhdhusers",
  async (pageNumber, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/user?page=${pageNumber}`, {
        headers,
      });
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removedhUsers = createAsyncThunk(
  "dhusers/removedhusers",
  async (userid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(`${endpoint}/api/${roleName}/user/${userid}`, {
        headers,
      });
      const data = await res.data.data;
      return userid;
    } catch (error) {
      return rejectWithValue(error.response.data);
      // console.log(error);
    }
  }
);

const usersListSlice = createSlice({
  name: "pmusers",
  initialState: { isLoading: true, pmUsers: []},
  reducers: {},
  extraReducers: {
    [getPmUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPmUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pmUsers = action.payload;
    },
    [getPmUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});



const usersListSlicedh = createSlice({
  name: "dhusers",
  initialState: { isLoading: true, dhUsers: [], pagesnumber: "", alldhUsers: [] },
  reducers: {},
  extraReducers: {
    // dhUsers
    [getDhUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getDhUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dhUsers = action.payload;
    },
    [getDhUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // getAllDhMemeber
    [getAllDhMemeber.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllDhMemeber.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.alldhUsers = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getAllDhMemeber.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // removedhUsers
    [removedhUsers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [removedhUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.alldhUsers = state.alldhUsers.filter((user) => user.id !== action.payload);
    },
    [removedhUsers.rejected]: (state, action) => {
      state.isLoading = false;
    },


  },
});

export default usersListSlice.reducer;
export const dhUsersReducer = usersListSlicedh.reducer;