import React from "react";

function NotAuthPage() {
  return (
    <div className="noauth">
      <div className="wrap">
        <img src={`${process.env.PUBLIC_URL}/images/shield.jpg`} alt="" />
        <h5>ليس لديك الصلاحيه للدخول الى هذه الصفحه</h5>
      </div>
    </div>
  );
}

export default NotAuthPage;
