import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function TablePaginaiton({ totlepages, setPage, page, handlepageChange }) {
  totlepages = parseInt(totlepages);
  return (
    <Stack spacing={2}>
      <Pagination count={totlepages} page={page} onChange={handlepageChange} />
    </Stack>
  );
}

export default TablePaginaiton;
