import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
} else if (role_id == "4") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}

export const getDitricts = createAsyncThunk(
  "ditricts/fetshditricts",
  async ({ pageNumber, dhId, pmId, arId }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district?page=${pageNumber}&dh_id=${dhId}&pm_id=${pmId}&area_id=${arId}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addfileDitricts = createAsyncThunk(
  "ditricts/addfileditricts",
  async (ditrictsFile, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/district/kmlImport`,
        ditrictsFile,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addsingleDitricts = createAsyncThunk(
  "ditricts/addsingleditricts",
  async (disticetData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/district`,
        disticetData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDitrict = createAsyncThunk(
  "ditricts/addfileditricts",
  async (districtId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(
        `${endpoint}/api/${roleName}/district/${districtId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return districtId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDitrictsByDh = createAsyncThunk(
  "ditricts/fetshditrictsbydh",
  async (dhID, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district/unassigned/${dhID}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getallUnassignedDistricts = createAsyncThunk(
  "ditricts/fetsallunassigneddistricts",
  async (pageNumber, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district/unassigned?page=${pageNumber}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUnAssignedDistricts = createAsyncThunk(
  "ditricts/fetsunassigneddistricts",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district/unassigned/level1`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDistrictById = createAsyncThunk(
  "ditricts/getDistrictById",
  async (disId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district/${disId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editDistrict = createAsyncThunk(
  "ditricts/editDistrict",
  async ({ disId, disdetails }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // console.log(disId);
      // console.log(disdetails);

      const res = await axios.post(
        `${endpoint}/api/${roleName}/district/${disId}`,
        disdetails,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const editDistrictDh = createAsyncThunk(
  "ditricts/editDistrictDh",
  async ({ disId, disdetails }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };


      const res = await axios.post(
        `${endpoint}/api/${roleName}/district/${disId}`,
        disdetails,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getThePolyganandBuildings = createAsyncThunk(
  "ditricts/getThePolyganandBuildings",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/district`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const ditrictsSlice = createSlice({
  name: "ditricts",
  initialState: {
    isLoading: true,
    ditricts: [],
    districtsByDh: [],
    singlditrict: {},
    pagesnumber: "",
    unassignedpagenumber: "",
    allUnassignedDistricts: [],
    unassignedDistricts: [],
    singlditrict2: {},
    districtpolyganandbuildongs: [],
  },
  reducers: {},
  extraReducers: {
    [getDitricts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getDitricts.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.ditricts = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getDitricts.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getallUnassignedDistricts
    [getallUnassignedDistricts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getallUnassignedDistricts.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.allUnassignedDistricts = action.payload.data;
      state.unassignedpagenumber = action.payload.pagination.last_page;
    },
    [getallUnassignedDistricts.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addfileDitricts
    [addfileDitricts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addfileDitricts.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [addfileDitricts.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteDitrict
    [deleteDitrict.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteDitrict.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.ditricts = state.ditricts.filter(
        (ditrict) => ditrict.id !== action.payload
      );
    },
    [deleteDitrict.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addsingleDitricts
    [addsingleDitricts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addsingleDitricts.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [addsingleDitricts.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getDitrictsByDh
    [getDitrictsByDh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getDitrictsByDh.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.districtsByDh = action.payload;
    },
    [getDitrictsByDh.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getUnAssignedDistricts
    [getUnAssignedDistricts.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUnAssignedDistricts.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.unassignedDistricts = action.payload;
    },
    [getUnAssignedDistricts.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getDistrictById
    [getDistrictById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getDistrictById.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.singlditrict2 = action.payload;
    },
    [getDistrictById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editDistrict
    [editDistrict.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editDistrict.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [editDistrict.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editDistrictDh
    [editDistrictDh.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editDistrictDh.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [editDistrictDh.rejected]: (state, action) => {
      state.isLoading = false;
    },


    // getThePolyganandBuildings
    [getThePolyganandBuildings.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getThePolyganandBuildings.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.districtpolyganandbuildongs = action.payload;
    },
    [getThePolyganandBuildings.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default ditrictsSlice.reducer;
