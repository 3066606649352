import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
} else if (role_id == "4") {
  roleName = "tm";
} else if (role_id == "5") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}

export const getBuildings = createAsyncThunk(
  "building/fetshbuildings",
  async ({ pageNumber, status, implemented_date_from, implemented_date_to, post_code }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/building?page=${pageNumber}&status=${status}&implemented_date_from=${implemented_date_from}&implemented_date_to=${implemented_date_to}&post_code=${post_code}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadBuildingData = createAsyncThunk(
  "building/downloadBuildingData",
  async ({ pageNumber, status, implemented_date_to, implemented_date_from, post_code }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/export?page=${pageNumber}&status=${status}&implemented_date_to=${implemented_date_to}&implemented_date_from=${implemented_date_from}&post_code=${post_code}`,
        {
          headers,
        }
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// deleteBuilding
export const deleteBuilding = createAsyncThunk(
  "building/deltebuildings",
  async (buildId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(
        `${endpoint}/api/${roleName}/building/${buildId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return buildId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const AddBulkbuildings = createAsyncThunk(
  "building/fetshbulkbuildings",
  async (fileBuildings, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/building/bulk`,
        fileBuildings,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBuildingById = createAsyncThunk(
  "building/getbuildingbyid",
  async (buid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/building/${buid}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editBuilding = createAsyncThunk(
  "building/getbuildingbyid",
  async ({ buid, buData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.patch(
        `${endpoint}/api/${roleName}/building/${buid}`,
        buData,
        {
          headers,
        }
      );
      const data = await res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getImageBuilding = createAsyncThunk(
  "building/getimagebuilding",
  async (buid, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/building/${buid}/media`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const buildingsSlice = createSlice({
  name: "building",
  initialState: {
    isLoading: true,
    buildings: [],
    singleBuilding: {},
    pagesnumber: "",
  },
  reducers: {},
  extraReducers: {
    [getBuildings.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getBuildings.fulfilled]: (state, action) => {
      // console.log(action.payload.pagination.last_page);
      state.isLoading = false;
      state.buildings = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getBuildings.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // AddBulkbuildings
    [AddBulkbuildings.pending]: (state, action) => {
      state.isLoading = true;
    },
    [AddBulkbuildings.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [AddBulkbuildings.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteBuilding
    [deleteBuilding.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteBuilding.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.buildings = state.buildings.filter(
        (build) => build.id !== action.payload
      );
    },
    [deleteBuilding.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getBuildingById
    [getBuildingById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getBuildingById.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.singleBuilding = action.payload;
    },
    [getBuildingById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editBuilding
    [editBuilding.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editBuilding.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [editBuilding.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getImageBuilding
    [getImageBuilding.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getImageBuilding.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [getImageBuilding.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // downloadBuildingData
    [downloadBuildingData.pending]: (state, action) => {
      state.isLoading = true;
    },
    [downloadBuildingData.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
    },
    [downloadBuildingData.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default buildingsSlice.reducer;
