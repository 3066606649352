import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, editArea, getAreaById } from "../../store/areasSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { getAllDhMemeber } from "../../store/usersLists";
import { editDistrict, getDistrictById } from "../../store/districtsSlice";
import { getCities } from "../../store/citiesSlice";

function EditDistrict() {
  const params = useParams();
  const dispatch = useDispatch();
  const dhusers = useSelector((state) => state.dhUsersReducer.alldhUsers);
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const singlearea = useSelector((state) => state.areasSlice.singlearea);
  const cities = useSelector((state) => state.allCities.cities);
  const [loading, setLoding] = useState(true);
  const disId = params.id;
  const [inputs, setInputs] = useState({
    code: "",
    status: "",
    dh_id: "",
    city_id: "",
  });
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  }

  useEffect(() => {
    dispatch(getAllDhMemeber(1));
    dispatch(getDistrictById(disId))
      .unwrap()
      .then((data) => {
        setLoding(false);
        // console.log(data);
        setInputs({
          ...inputs, // Spread the existing properties
          code: data.code, // Update the 'code' property
          status: data.is_assigned_level1, // Update the 'status' property
          dh_id: data.assignedPM?.distributed_to, // Update the 'status' property
        });
      })
      .catch((error) => {
        toast.error(error.message);
        setLoding(false);
      });

    dispatch(getCities())
      .unwrap()
      .then((data) => {
        setLoding(false);
        console.log(data);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoding(false);
      });
  }, [dispatch]);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoding(true);
    // console.log(inputs)
    dispatch(editDistrict({ disId: disId, disdetails: inputs }))
      .unwrap()
      .then((data) => {
        setLoding(false);
        toast.success(`تم التعديل بنجاح`);
        navigate(`/${roleName}/district-list`);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoding(false);
      });
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="form_style">
          <div className="form_header">
            <h5>تعديل المنطقه</h5>
            <p>يرجي ادخال البيانات التالية</p>
          </div>
          <form onSubmit={saveData} action="">
            <div className="form-group">
              <div className="form-goup-label">
                <label htmlFor="">كود المنطقه:</label>
                <input
                  type="text"
                  name="code"
                  disabled={true}
                  value={inputs.code}
                  // onChange={handelInputChange}
                  className="form-control"
                  placeholder="يرجي ادخال رقم المنطقه الجديدة"
                  onBlur={() => simpleValidator.current.showMessageFor("code")}
                />
              </div>
              <div className="error">
                {simpleValidator.current.message(
                  "code",
                  singlearea.code,
                  "required"
                )}
              </div>
            </div>

            <div className="form-group">
              <div className="form-goup-label">
                <label htmlFor="">حاله المنطقه:</label>
                <select
                  name="status"
                  value={inputs.status}
                  id=""
                  onChange={handelInputChange}
                  className="form-control"
                >
                  <option value="">اختر</option>
                  <option value="true">موزعه</option>
                  <option value="false">غير موزعه</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <div className="form-goup-label">
                <label htmlFor="">رئيس القسم:</label>
                <select
                  name="dh_id"
                  value={inputs.dh_id}
                  onChange={handelInputChange}
                  id=""
                  className="form-control"
                >
                  <option value="" selected>
                    اختر
                  </option>
                  {dhusers.map((dh) => (
                    <option value={dh.id} key={dh.id}>
                      {dh.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group">
              <div className="form-goup-label">
                <label htmlFor="">المدينه:</label>
                <select
                  name="city_id"
                  value={inputs.city_id}
                  onChange={handelInputChange}
                  id=""
                  className="form-control"
                >
                  <option value="" selected>
                    اختر
                  </option>
                  {cities.map((cty) => (
                    <option value={cty.id} key={cty.id}>
                      {cty.name_ar}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="btn_submit">
              <button className="btn_style">تعديل</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default EditDistrict;
