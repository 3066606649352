import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
}else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}




export const getFilter = createAsyncThunk(
  "filters/fetshfilters",
  async ({type, userID}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/${type}/assigned/${userID}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const allfilterSlice = createSlice({
  name: "filters",
  initialState: { isLoading: true, filtersrow: []},
  reducers: {},
  extraReducers: {
    [getFilter.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getFilter.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.filtersrow = action.payload.data;
    },
    [getFilter.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default allfilterSlice.reducer;
