import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
}else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}

export const getAreas = createAsyncThunk(
  "areas/fetshareas",
  async ({pageNumber, dhId}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/area?page=${pageNumber}&dh_id=${dhId}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUnAssignedAreas = createAsyncThunk(
  "areas/fetshunassignedareas",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/area/unassigned/list`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAreaById = createAsyncThunk(
  "areas/getbyidfetshareas",
  async (areaId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(`${endpoint}/api/${roleName}/area/${areaId}`, {
        headers,
      });
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addAreas = createAsyncThunk(
  "areas/addfetshareas",
  async (areaData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/area`,
        areaData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignArea = createAsyncThunk(
  "areas/assignfetshareas",
  async (areaData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.post(
        `${endpoint}/api/${roleName}/area/assign`,
        areaData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editArea = createAsyncThunk(
  "areas/editfetshareas",
  async (areaData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.put(
        `${endpoint}/api/${roleName}/area/${areaData.id}`,
        areaData,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteArea = createAsyncThunk(
  "areas/deletefetshareas",
  async (areaId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.delete(
        `${endpoint}/api/${roleName}/area/${areaId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      return areaId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const areasSlice = createSlice({
  name: "areas",
  initialState: { isLoading: true, areas: [], singlearea: {}, pagesnumber: "", unassignedareas:[], unassignedareaspage: "" },
  reducers: {},
  extraReducers: {
    [getAreas.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAreas.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.areas = action.payload.data;
      state.pagesnumber = action.payload.pagination.last_page;
    },
    [getAreas.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getUnAssignedAreas
    [getUnAssignedAreas.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getUnAssignedAreas.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.unassignedareas = action.payload.data;
      state.unassignedareaspage = action.payload.pagination.last_page;
    },
    [getUnAssignedAreas.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // addAreas
    [addAreas.pending]: (state, action) => {
      state.isLoading = true;
    },
    [addAreas.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.areas.push(action.payload);
    },
    [addAreas.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // deleteArea
    [deleteArea.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteArea.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.areas = state.areas.filter((area) => area.id !== action.payload);
    },
    [deleteArea.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getAreaById
    [getAreaById.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAreaById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singlearea = action.payload;
    },
    [getAreaById.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // editArea
    [editArea.pending]: (state, action) => {
      state.isLoading = true;
    },
    [editArea.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.singlearea = action.payload;
    },
    [editArea.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // assignArea
    [assignArea.pending]: (state, action) => {
      state.isLoading = true;
    },
    [assignArea.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.singlearea = action.payload;
    },
    [assignArea.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default areasSlice.reducer;
