import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PassDialog({
  userpass,
  openDialog,
  setOpenDialog,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const localrole_id = localStorage.getItem("role_id");
  var roleName = "";
  if (localrole_id == "1") {
    roleName = "superadmin";
  } else if (localrole_id == "2") {
    roleName = "pm";
  } else if (localrole_id == "3") {
    roleName = "dh";
  }

  const handleClose = () => {
    setOpenDialog(false);
    navigate(`/${roleName}/all-users`);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="history_dialog"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"الرقم السري"}</DialogTitle>
        <DialogContent>
          <div id="alert-dialog-slide-description">
            <p className="notfoundhistory">برجاء الاحتفاظ بالرقم السري</p>
            <p className="notfoundhistory">{userpass}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
