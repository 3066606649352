import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../components/DataTable";
import LockResetIcon from "@mui/icons-material/LockReset";
import CreateIcon from "@mui/icons-material/Create";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserPass,
  getUsers,
  removeUsers,
} from "../store/superadmin/usersSlice";
import { Link } from "react-router-dom";
import TablePaginaiton from "../components/TablePaginaiton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loading from "../components/Loading";
import {
  getAllDhMemeber,
  getDhUsers,
  removedhUsers,
} from "../store/usersLists";
import { toast } from "react-toastify";
import NewPassDialog from "../components/NewPassDialog";

function AllMember() {
  const dispatch = useDispatch();
  const dhusers = useSelector((state) => state.dhUsersReducer.alldhUsers);
  const totlepages = useSelector((state) => state.dhUsersReducer.pagesnumber);
  // const isLoading = useSelector((state) => state.dhUsersReducer.isLoading);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const selectionRows = false;
  const [loading, setLoading] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const newpassword = useSelector((state) => state.usersSlice.newpassword);
  const localrole_id = localStorage.getItem("role_id");
  var roleName = "";
  if (localrole_id == "1") {
    roleName = "superadmin";
  } else if (localrole_id == "2") {
    roleName = "pm";
  } else if (localrole_id == "3") {
    roleName = "dh";
  }

  useEffect(() => {
    dispatch(getAllDhMemeber(1))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(removedhUsers(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const handlepageChange = (event, value) => {
    setPage(value);
    dispatch(getUsers(value));
  };

  const changePass = (userId) => {
    // console.log(userId);
    setLoading(true);
    dispatch(changeUserPass(userId)).then((data) => {
      // console.log("data");
      setLoading(false);
      setOpenDialog(true);
    });
  };

  var coulmns = [
    { field: "id", headerName: "رقم التعريف" },
    { field: "name", headerName: "الاسم", editable: true },
    {
      field: "email",
      headerName: "البريد الإلكتروني",
      editable: true,
      width: 200,
    },
    { field: "role_name", headerName: "المنصب", editable: true },
    { field: "national_id", headerName: "الرقم الوطني", editable: true },
    { field: "employee_id", headerName: "رقم الموظف", editable: true },
    { field: "phone_num", headerName: "رقم الهاتف", editable: true },
    { field: "address", headerName: "العنوان", editable: true },
    { field: "birthdate", headerName: "تاريخ الميلاد", editable: true },
    { field: "license_type", headerName: "نوع الترخيص", editable: true },
    {
      field: "districts_assigned",
      headerName: "المناطق الموزعه",
      renderCell: (params) => (
        <div className="tableoptions">
          <Link
            to={`/${roleName}/allfilter/district/${params.row.id}`}
            className="number_of_items"
          >
            {params.row.districts_assigned}
          </Link>
        </div>
      ),
    },
    {
      field: "buildings_assigned",
      headerName: "اللوحات الموزعه",
      renderCell: (params) => (
        <div className="tableoptions">
          <Link
            to={`/${roleName}/allfilter/building/${params.row.id}`}
            className="number_of_items"
          >
            {params.row.buildings_assigned}
          </Link>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "اختيارات",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="change-pass">
            <LockResetIcon
              onClick={() => changePass(params.row.id)}
            ></LockResetIcon>
          </span>
          <span className="editicon">
            <Link
              to={`/${roleName}/edit-user/${params.row.role_name}/${params.row.id}`}
            >
              <CreateIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>

          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  // Conditionally remove the "districts_assigned" field if localrole_id is 3
  if (localrole_id == 3) {
    coulmns = coulmns.filter((column) => column.field !== "districts_assigned");
  }

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <>
        <NewPassDialog
          newpassword={newpassword}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
        <div className="manage_employee">
          <div className="add_button">
            {localrole_id == 2 ? (
              <Link to={`/${roleName}/add-new-user/dh`}>اضافة رئيس قسم</Link>
            ) : (
              ""
            )}

            {localrole_id == 3 ? (
              <Link to={`/${roleName}/add-new-user/tm`}>اضافة عضو فريق</Link>
            ) : (
              ""
            )}
          </div>

          <Grid container spacing={8} className="algin-center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={11}
              className="order2intablet"
            >
              <div className="table_show table_style">
                {localrole_id == 2 ? <h5>قائمة بجميع رؤساء الاقسام</h5> : ""}
                {localrole_id == 3 ? <h5>قائمة بجميع اعضاء الفرق</h5> : ""}

                {/* <a href="" className="showall">
        عرض الكل
      </a> */}
                <DataGridDemo
                  coulmns={coulmns}
                  rows={dhusers}
                  selectionRows={selectionRows}
                />

                {/* <TablePaginaiton
        totlepages={totlepages}
        page={page}
        setPage={setPage}
        handlepageChange={handlepageChange}
      /> */}
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={3}
              className="order1intablet nopaadingmobile"
            >
              {localrole_id == "2" || localrole_id == "3" ? (
                ""
              ) : (
                <div className="options_buttons">
                  <ul className="list-unstyled">
                    <li>
                      <a href="">قائمة المشرفين</a>
                    </li>
                    <li>
                      <a href="">قائمة الفنيين</a>
                    </li>
                  </ul>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default AllMember;
