import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import CircularChart from "../../components/CircularChart";
import BarChart from "../../components/BarChart";
import { useDispatch, useSelector } from "react-redux";
import { getStatics } from "../../store/staticsSlice";
import Loading from "../../components/Loading";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { toast } from "react-toastify";

function TeamStatics() {
  const dispatch = useDispatch();
  const [statics, setStatiscs] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [datesArray, setDatesArray] = useState([]);
  const [implementedArray, setImplementedArray] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  const [filterDateValue, setFilterDateValue] = useState({
    date1: "",
    date2: "",
  });
  // console.log(statics.days);

  // const datesArray = statics.days.map((item) => item.date);
  // const implementedArray = statics.days.map((item) => item.implemented);

  // console.log(datesArray)
  // console.log(implementedArray)

  useEffect(() => {
    dispatch(
      getStatics({ date1: filterDateValue.date1, date2: filterDateValue.date2 })
    )
      .unwrap()
      .then((statics) => {
        if (statics.code == 200) {
          setStatiscs(statics.data);
          setDatesArray(statics.data.days.map((item) => item.date));
          setImplementedArray(
            statics.data.days.map((item) => item.implemented)
          );
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
        setIsLoading(false);
      });
  }, [dispatch, filterVal]);

  const handelFilter = (e) => {
    // console.log(e.target.value)
    setLoading(true);
    setFilterVal(e.target.value);
    // getTheStatistics();
  };

  const handelDateChange = (e) => {
    const { name, value } = e.target;
    setFilterDateValue((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const filterByDate = (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(
      getStatics({ date1: filterDateValue.date1, date2: filterDateValue.date2 })
    )
      .unwrap()
      .then((statics) => {
        if (statics.code == 200) {
          setStatiscs(statics.data);
          setDatesArray(statics.data.days.map((item) => item.date));
          setImplementedArray(
            statics.data.days.map((item) => item.implemented)
          );
          setLoading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
        setIsLoading(false);
      });
  };

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <div className="statics_wrapper">
        <div className="statics_cards">
          <Container maxWidth="xxl">
            <Grid container spacing={2} className="algin-center">
              <Grid sm={12} xs={12} md={12}>
                <div className="statics_filter">
                  <h5>تصفية</h5>

                  <form action="" onSubmit={filterByDate}>
                    <div className="form_group">
                      <label htmlFor="">من</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={handelDateChange}
                        value={filterDateValue.date1}
                        name="date1"
                      />
                    </div>

                    <div className="form_group">
                      <label htmlFor="">الى</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={handelDateChange}
                        value={filterDateValue.date2}
                        name="date2"
                      />
                    </div>

                    <div className="form_group">
                      <label htmlFor="" style={{ color: "#fff" }}>
                        .
                      </label>
                      <button className="btn_style">تصفيه</button>
                    </div>
                  </form>
                </div>
              </Grid>

              <Grid xs={12} md={4}>
                <div className="statics_card">
                  <h5>اجمالي اللوحات</h5>
                  <p className="number">{statics.buildings.total}</p>
                </div>
              </Grid>

              <Grid xs={12} md={4}>
                <div className="statics_card">
                  <h5>لوحات تم تركيبها</h5>
                  <p className="number">
                    {statics.buildings.implemented.number}
                  </p>
                </div>
              </Grid>

              <Grid xs={12} md={4}>
                <div className="statics_card">
                  <h5>لوحات قيد الانتظار</h5>
                  <p className="number">
                    {statics.buildings.unImplemented.number}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container maxWidth="xxl">
          <Grid container spacing={2} className="algin-center">
            <Grid xs={12} md={6}>
              <div className="box_style_bg">
                <div className="box_header day_box">
                  <h5>تفاصيل التركيب</h5>
                </div>
                <BarChart
                  height={200}
                  datesArray={datesArray}
                  implementedArray={implementedArray}
                />
              </div>
            </Grid>

            <Grid xs={12} md={4}>
              <div className="box_style_bg list_reports_bg">
                <div className="box_header day_box">
                  <h5>قائمة التقارير</h5>
                </div>
                <ul className="reports_list reports_list_team">
                  <li>
                    {" "}
                    <span>التقرير الشهريpdf</span>{" "}
                    <a
                      download={true}
                      target="_blank"
                      href={`${statics.general_report_pdf}`}
                    >
                      {" "}
                      <span>تحميل</span>{" "}
                      <CloudDownloadOutlinedIcon></CloudDownloadOutlinedIcon>{" "}
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default TeamStatics;
