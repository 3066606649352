import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataGridDemo from "../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getFilter } from "../store/allfilterSlice";
import Loading from "../components/Loading";
import { toast } from "react-toastify";

function AllFilter() {
  const params = useParams();
  const dispatch = useDispatch();
  const filterrows = useSelector((state) => state.allfilterSlice.filtersrow);
  const [loading, setLoading] = useState(true);
  var [coulmns, setCoulmns] = useState([]);

  useEffect(() => {
    if (params.type === "district") {
      setCoulmns([
        { field: "id", headerName: "الرقم التعريفي", width: 100 },
        { field: "code", headerName: "الرمز البريدي", editable: true, width: 180 },
      ]);
    } else if (params.type === "building") {
      setCoulmns([
        { field: "id", headerName: "ID", width: 150 },
        {
          field: "building_code",
          headerName: "كود المبنى",
          editable: true,
          width: 150,
        },
        // { field: "longitude", headerName: "الطول", editable: true, width: 150 },
        // { field: "latitude", headerName: "العرض", editable: true, width: 150 },
        { field: "post_code", headerName: "الرمز البريدي", editable: true, width: 150 },
        {
          field: "status",
          headerName: "الحاله",
          editable: false,
          width: 150,
          valueGetter: (params) => {
            const statusValue = params.row.status;
            if (statusValue === "1") {
              return "قيد الانتظار";
            } else if (statusValue === "3") {
              return "تم التركيب";
            } else {
              return "Unknown"; // You can set a default label for other values if needed
            }
          },
          cellClassName: (params) => {
            if (params.value === "قيد الانتظار") {
              // Add your CSS class for background and color styles here
              return "pending-status";
            }
            if (params.value === "تم التركيب") {
              // Add your CSS class for background and color styles here
              return "done-status";
            }
            // You can return other classes or leave it empty for default styling
            return "";
          },
        },
        { field: "qr", headerName: "الباركود", editable: true, width: 150 },
      ]);
    }

    dispatch(getFilter({ type: params.type, userID: params.id }))
      .unwrap()
      .then((data) => {
        console.log(data);
        setLoading(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  }, [dispatch, params.type, params.id]);

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="table_show table_style">
          <DataGridDemo coulmns={coulmns} rows={filterrows} />
        </div>
      )}
    </>
  );
}

export default AllFilter;
