import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, getAreas } from "../../store/areasSlice";
import { addfileDitricts } from "../../store/districtsSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import { getBuildingById, editBuilding } from "../../store/buildingSlice";


function EditBuildingStatus() {
  const dispatch = useDispatch();
  const params = useParams();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const buId = params.buId;
  const navigate = useNavigate();
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  }
  useEffect(() => {

    dispatch(getBuildingById(buId)).unwrap().then((data) => {
      setStatus(data.status)
      setLoading(false)
    })

  }, [dispatch])


  const handelChange = (e) => {
    setStatus(e.target.value);
  }


  const sendData = (e) => {
    e.preventDefault();
    const newstatus = {
      "status":status
    }
    console.log(newstatus)
    dispatch(editBuilding({ buid: buId, buData: newstatus })).unwrap().then((data) => {
      toast.success("تم التعديل بنجاح");
      navigate(`/${roleName}/building-list`)
    }).catch((error)=>{
      toast.error(error.message);
    })
  }

  return (
    <>
      {loading ? <Loading open={true} /> : <>
        <div className="form_style">
          <div className="form_header">
            <h5>تعديل حالة المبنى</h5>
            <p>يرجي ادخال البيانات التالية</p>
          </div>
          <form onSubmit={sendData} action="">
            <div className="form-group">
              <div className="form-goup-label">
                <label htmlFor="">الحاله:</label>
                {/* <input
                type="file"
                name="kml_file"
                onChange={handelUploadPicture}
                className="form-control"
                placeholder="يرجي ادخال اسم المنطقه الجديدة"
                onBlur={() =>
                  simpleValidator.current.showMessageFor("kml_file")
                }
              /> */}

                <select name="status" id="" value={status} onChange={handelChange} className="form-control">
                  <option value="1">قيد الانتظار</option>
                  <option value="3">تم التركيب</option>
                </select>

              </div>
            </div>

            <div className="btn_submit">
              <button className="btn_style">حفظ</button>
            </div>
          </form>
        </div>
      </>}


    </>
  )
}

export default EditBuildingStatus