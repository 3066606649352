import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, getAreas } from "../../store/areasSlice";
import PolyganMap from "../../components/PolyganMap";
import {
  addsingleDitricts,
  getDitrictsByDh,
  getallUnassignedDistricts,
} from "../../store/districtsSlice";
import { getDhUsers } from "../../store/usersLists";
import {
  assignDistrictToTeam,
  getTallDhTeams,
  getTeamByDh,
} from "../../store/teamSlice";
import Loading from "../../components/Loading";
import DataGridDemo from "../../components/DataTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TablePaginaiton from "../../components/TablePaginaiton";

function DhAssignDistrictsToTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totlepages = useSelector((state) => state.ditrictsSlice.pagesnumber);
  const alldhteams = useSelector((state) => state.teamSlice.allDhTeam);
  const alldhunassigneddistrics = useSelector(
    (state) => state.ditrictsSlice.allUnassignedDistricts
  );
  const [page, setPage] = useState(1);
  const teamByDh = useSelector((state) => state.teamSlice.teamByDh);
  const unassignedDistrictsbydh = useSelector(
    (state) => state.ditrictsSlice.districtsByDh
  );
  const selectionRows = true;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoding] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [inputs, setInputs] = useState({
    dh_id: "",
    team_id: "",
    district_id: "",
    implementation_period: "",
  });
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  }

  const coulmns = [
    { field: "id", headerName: "ID" },
    { field: "code", headerName: "الرمز البريدي", editable: true },
  ];

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  // const handelTeamChange = (event) => {
  //   const { name, value } = event.target;
  //   setInputs((prevInputs) => ({ ...prevInputs, team_id: value }));
  // };

  // const handlepageChange = (event, value) => {
  //   setPage(value);
  //   console.log(value);
  //   dispatch(getallUnassignedDistricts(value));
  // };

  const handelInputarrChange = (event) => {
    const { name, value } = event.target;
    var districtarr = [];
    districtarr.push(value);
    // console.log(districtarr);
    setInputs((prevInputs) => ({ ...prevInputs, district_id: districtarr }));
  };

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    dispatch(getTallDhTeams())
      .unwrap()
      .then((data) => {
        setLoding(false);
        // console.log(data);
      });

    dispatch(getallUnassignedDistricts(1))
      .unwrap()
      .then((data) => {
        setLoding(false);
      }).catch((error) => {
        toast.error(error.message);
        setLoding(false);
      });
  }, [dispatch]);

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoding(true);
      console.log(inputs);
      dispatch(assignDistrictToTeam(inputs))
        .unwrap()
        .then((data) => {
          setLoding(false);
          toast.success(`تم التوزيع بنجاح`);
          navigate(`/${roleName}/district-list`);
        })
        .catch((error) => {
          setLoding(false);
          toast.error(`${error.message}`);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>توزيع مناطق على الفريق</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form onSubmit={saveData} action="">
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">الفريق:</label>
                  <select
                    name="team_id"
                    value={inputs.team_id}
                    id=""
                    className="form-control"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("team_id")
                    }
                  >
                    <option value="" selected disabled>
                      اختر
                    </option>
                    {alldhteams.map((tmhd) => (
                      <option key={tmhd.id} value={tmhd.id}>
                        {tmhd.supervisor.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "team_id",
                    inputs.team_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">المنطقه:</label>
                  <select
                    name="district_id"
                    value={inputs.district_id}
                    id=""
                    className="form-control"
                    onChange={handelInputarrChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("district_id")
                    }
                  >
                    <option value="" selected disabled>
                      اختر
                    </option>
                    {alldhunassigneddistrics.map((dis) => (
                      <option key={dis.id} value={dis.id}>
                        {dis.code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "district_id",
                    inputs.district_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">عدد الاسابيع المحتمله:</label>
                  <input
                    type="number"
                    min={1}
                    className="form-control"
                    name="implementation_period"
                    onChange={handelInputChange}
                    id=""
                    value={inputs.implementation_period}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("implementation_period")
                    }
                  />
                </div>
                <div className="error">
                    {simpleValidator.current.message(
                      "implementation_period",
                      inputs.implementation_period,
                      "required"
                    )}
                  </div>
              </div>

              {/* <h5 className="titlefor_table">اختر المناطق</h5> */}
              {/* <DataGridDemo
                selectionRows={selectionRows}
                coulmns={coulmns}
                rows={alldhunassigneddistrics}
                handleRowSelection={handleRowSelection}
              /> */}

              {/* <TablePaginaiton
                totlepages={totlepages}
                page={page}
                setPage={setPage}
                handlepageChange={handlepageChange}
              /> */}

              <div className="btn_submit">
                <button className="btn_style" type="submit">
                  توزيع
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default DhAssignDistrictsToTeam;
