import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, getAreas } from "../../store/areasSlice";
import { addfileDitricts } from "../../store/districtsSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

function AddDistrictKMl() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoding] = useState(false);
  const areas = useSelector((state)=> state.areasSlice.areas);
  const isLoading = useSelector((state)=> state.areasSlice.isLoading);
  const [inputs, setInputs] = useState({
    kml_file: "",
    // area_id: "",
  });

  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  }


  useEffect(()=>{
    dispatch(getAreas({ pageNumber: 0, dhId: "" }));
  }, [dispatch])

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const handelUploadPicture = (e) => {
    const { name, files } = e.target;

    // Assuming you are updating the state with the selected file
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: files[0], // Update the specific input with the file object
    }));
  };

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoding(true);

      const formData = new FormData();
      formData.append("kml_file", inputs.kml_file);
      Object.keys(inputs).forEach((key) => {
        formData.append(key, inputs[key]);
      });

      dispatch(addfileDitricts(formData))
        .unwrap()
        .then((data) => {
          setLoding(false);
          toast.success(`تم الاضافه بنجاح`);
          navigate(`/${roleName}/district-list`);
        })
        .catch((error) => {
          setLoding(false);
          toast.error(`${error.message}`);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };


  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>إضافة ملف المناطق</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form onSubmit={saveData} action="">
              {/* <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">كود المنطقه:</label>
                  <select
                    name="area_id"
                    value={inputs.area_id}
                    onChange={handelInputChange}
                    id=""
                    className="form-control"
                  >
                    <option value="" selected disabled>
                      اختر الكود
                    </option>
                    {areas.map((ar)=>(
                      <option value={ar.id} key={ar.id}>{ar.code}</option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "area_id",
                    inputs.area_id,
                    "required"
                  )}
                </div>
              </div> */}

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">الملف:</label>
                  <input
                    type="file"
                    name="kml_file"
                    onChange={handelUploadPicture}
                    className="form-control"
                    placeholder="يرجي ادخال اسم المنطقه الجديدة"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("kml_file")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "kml_file",
                    inputs.kml_file,
                    "required"
                  )}
                </div>
              </div>

              <div className="btn_submit">
                <button className="btn_style">اضافة</button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default AddDistrictKMl;
