import React from 'react'

function NotFound() {
  return (
    <div className="noauth">
    <div className="wrap">
      <img src={`${process.env.PUBLIC_URL}/images/error.jpg`} alt="" />
      <h5>هذه الصفحه غير موجوده</h5>
    </div>
  </div>
  )
}

export default NotFound