import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
} else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}


export const getHistory = createAsyncThunk(
  "history/fetshHistory",
  async ({place,placeId}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/${place}/history/${placeId}`,
        {
          headers,
        }
      );
      const data = await res.data.data;
      // console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const historySlice = createSlice({
  name: "history",
  initialState: {
    isLoading: true,
    history: [],
  },
  reducers: {},
  extraReducers: {
    [getHistory.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getHistory.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.history = action.payload;
    },
    [getHistory.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default historySlice.reducer;