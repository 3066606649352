import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import HistoryIcon from "@mui/icons-material/History";
import { useDispatch, useSelector } from "react-redux";
import { deleteArea, getAreas } from "../../store/areasSlice";
import { Link } from "react-router-dom";
import TablePaginaiton from "../../components/TablePaginaiton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import { getDhUsers } from "../../store/usersLists";
import { getHistory } from "../../store/historySlice";
import HistoryDialog from "../../components/HistoryDialog";

function AreaList() {
  const dispatch = useDispatch();
  const historiews = useSelector((state) => state.historySlice.history);
  const areas = useSelector((state) => state.areasSlice.areas);
  const totlepages = useSelector((state) => state.areasSlice.pagesnumber);
  const [loading, setLoading] = useState();
  const dhusers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const [dhId, setDhId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    dispatch(getAreas({ pageNumber: 0, dhId: "" }))
      .unwrap()
      .then((data) => {
        setIsLoading(false);
      });
    dispatch(getDhUsers())
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      });
  }, [dispatch]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);

    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteArea(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "4") {
    roleName = "tm";
  }

  const handlepageChange = (event, value) => {
    setPage(value);
    dispatch(getAreas(value));
  };

  const handelHistoryDialog = (placeId) => {
    setLoading(true);
    dispatch(getHistory({ place: "area", placeId: placeId }))
      .unwrap()
      .then((data) => {
        setOpenDialog(true);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const coulmns = [
    { field: "id", headerName: "رقم التعريف", width: 150 },
    { field: "code", headerName: "الرمز البريدي", editable: true, width: 150 },
    // { field: "is_assigned", headerName: "هل موزعه", editable: true },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   // width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <Link to={`/superadmin/edit-area/${params.row.id}`}>
    //         <EditNoteOutlinedIcon style={{ cursor: "pointer" }} />
    //       </Link>

    //       <Delete
    //         onClick={() => handledelete(params.row.id)}
    //         style={{ cursor: "pointer" }}
    //       />
    //     </>
    //   ),
    // },
  ];

  // if (role_id !== "3") {
  //   coulmns.push({
  //     field: "is_assigned",
  //     headerName: "هل موزعه",
  //     editable: true,
  //     width: 200,
  //   });
  // }

  if (role_id !== "3") {
    coulmns.push({
      field: "actions",
      headerName: "Actions",
      // width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          {/* <span className="editicon">
            <Link to={`/${roleName}/edit-area/${params.row.id}`}>
              <CreateIcon style={{ cursor: "pointer" }} />
            </Link>
          </span> */}
          <span className="historyicon">
            <HistoryIcon
              onClick={() => handelHistoryDialog(params.row.id)}
            ></HistoryIcon>
          </span>
          {/* <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span> */}
        </div>
      ),
    });
  }

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <>
        <HistoryDialog
          historiews={historiews}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleClickOpen={handleClickOpen}
        />

        <div className="manage_employee plates_list">
          {/* <div className="add_button">
              {role_id == "2" ? (
                <Link to={`/${roleName}/pmnnewara`}>اضافة منطقه</Link>
              ) : (
                ""
              )}

              {role_id == "1" ? (
                <Link to={`/${roleName}/superadminnewara`}>اضافة منطقه</Link>
              ) : (
                ""
              )}
            </div> */}

          <Grid container spacing={8} className="algin-center">
            <Grid sm={12} xs={12} md={11}>
              <div className="table_show table_style">
                <h5>قائمة المساحات</h5>
                {/* 
                  <div className="filter">
                    <h5>تصفيه</h5>
                    <form action="">
                      <div className="form-group w-100">
                        <select
                          name=""
                          value={dhId}
                          onChange={(e) => {
                            setDhId(e.target.value);
                          }}
                          id=""
                          className="form-control"
                        >
                          <option value="" selected>
                            اختر رئيس القسم
                          </option>
                          {dhusers.map((dh) => (
                            <option value={dh.id} key={dh.id}>
                              {dh.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="btn_submit">
                        <button
                          className="btn"
                          onClick={() => {
                            dispatch(
                              getAreas({
                                pageNumber: 0,
                                dhId: dhId,
                              })
                            );
                          }}
                        >
                          بحث
                        </button>
                      </div>
                    </form>
                  </div> */}

                <button
                  onClick={() => {
                    dispatch(getAreas({ pageNumber: 0, dhId: "" }));
                  }}
                  className="showall"
                >
                  عرض الكل
                </button>
                <DataGridDemo coulmns={coulmns} rows={areas} />

                {/* <TablePaginaiton
          totlepages={totlepages}
          page={page}
          setPage={setPage}
          handlepageChange={handlepageChange}
        /> */}
              </div>
            </Grid>
            <Grid xs={3}>
              {/* <div className="options_buttons">
        <ul className="list-unstyled">
          <li>
            <a href="">المناطق الموزعه</a>
          </li>
          <li>
            <a href="">المناطق الغير موزعه</a>
          </li>
        </ul>
      </div> */}
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default AreaList;
