import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../components/DataTable";
import CreateIcon from "@mui/icons-material/Create";
import { Delete } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, removeUsers } from "../store/superadmin/usersSlice";
import { Link } from "react-router-dom";
import TablePaginaiton from "../components/TablePaginaiton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loading from "../components/Loading";
import { getAllDhMemeber, getDhUsers } from "../store/usersLists";
import { getTallDhTeams2, removeteam, removetmUsers } from "../store/teamSlice";
import { toast } from "react-toastify";

function AllTeams() {
  const dispatch = useDispatch();
  const dhteams = useSelector((state) => state.teamSlice.allDhTeam2);
  const totlepages = useSelector((state) => state.teamSlice.pagesnumber);
  const isLoading = useSelector((state) => state.teamSlice.isLoading);
  const [page, setPage] = useState(1);
  const selectionRows = false;
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "4") {
    roleName = "tm";
  }

  useEffect(() => {
    dispatch(getTallDhTeams2(0)).then((data)=>{
      console.log(data)
    })
  }, [dispatch]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);

    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () =>
            dispatch(removeteam(id))
              .unwrap()
              .then((data) => {
                toast.success("تم المسح بنجاح");
              }).catch((error) => {
                toast.error(error.message);
              })
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const coulmns = [
    { field: "id", headerName: "رقم التعريف", width: 200 },
    // { field: "name", headerName: "الاسم", editable: true, width: 400 },
    {
      field: "name",
      headerName: "الاسم",
      width: 300,
      renderCell: (params) => (
        <div className="">
          <span className="">
            {params.row.supervisor?.name}
          </span>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "اختيارات",
      // width: 100,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="editicon">
            <Link to={`/${roleName}/edit-team/${params.row.id}`}>
              <CreateIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>

          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  const handlepageChange = (event, value) => {
    setPage(value);
    dispatch(getUsers(value));
  };

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      <div className="manage_employee">
        <div className="add_button">
          <Link to={`/${roleName}/add-new-team`}>اضافة فريق</Link>
          <Link to={`/${roleName}/add-new-user/tm`}>اضافة عضو فريق</Link>
        </div>

        <Grid container spacing={8} className="algin-center">
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={9}
            className="order2intablet"
          >
            <div className="table_show table_style">
              <h5>قائمة بجميع الفرق</h5>
              <a href="" className="showall">
                عرض الكل
              </a>
              <DataGridDemo
                coulmns={coulmns}
                rows={dhteams}
                selectionRows={selectionRows}
              />

              {/* <TablePaginaiton
                totlepages={totlepages}
                page={page}
                setPage={setPage}
                handlepageChange={handlepageChange}
              /> */}
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={3}
            className="order1intablet nopaadingmobile"
          ></Grid>
        </Grid>
      </div>
    </>
  );
}

export default AllTeams;
