import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import Loading from "../components/Loading";
import { resetPassword } from "../store/profileSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function ResetPaswword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    old_password: "",
    password: "",
  });


  const localrole_id = localStorage.getItem("role_id");
  var roleName = "";
  if (localrole_id == "1") {
    roleName = "superadmin";
  } else if (localrole_id == "2") {
    roleName = "pm";
  } else if (localrole_id == "3") {
    roleName = "dh";
  }else if (localrole_id == "4") {
    roleName = "tm";
  }

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      
      dispatch(resetPassword(inputs))
        .unwrap()
        .then((data) => {
          setLoading(false);
          toast.success("تم التعديل بنجاح");
          navigate(`/${roleName}`);
        })
        .catch((error) => {
          // toast.error(`${error.message}`)

          setLoading(false);
          toast.error(error.message);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div>
          <div className="form_style">
            <div className="form_header">
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form action="" onSubmit={saveData}>
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">كلمه السر القديمه:</label>
                  <input
                    type="password"
                    name="old_password"
                    value={inputs.old_password}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="ادخل كلمه السر القديمه"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("old_password")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "old_password",
                    inputs.old_password,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">كلمة السر الجديده:</label>
                  <input
                    type="password"
                    name="password"
                    value={inputs.password}
                    onChange={handelInputChange}
                    className="form-control"
                    placeholder="ادخل كلمه السر الجديده"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("password")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "password",
                    inputs.password,
                    "required"
                  )}
                </div>
              </div>

              <div className="btn_submit">
                <button className="btn_style">حفظ</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPaswword;
