import React, { useEffect, useRef, useState } from "react";
import ReturnBtn from "../../components/BtnReturn";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { addAreas, getAreas } from "../../store/areasSlice";
import PolyganMap from "../../components/PolyganMap";
import { addsingleDitricts, getDitrictsByDh } from "../../store/districtsSlice";
import { getDhUsers } from "../../store/usersLists";
import { assignDistrictToTeam, getTeamByDh } from "../../store/teamSlice";
import Loading from "../../components/Loading";
import DataGridDemo from "../../components/DataTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AssignDistrict() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dhusers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const teamByDh = useSelector((state) => state.teamSlice.teamByDh);
  const unassignedDistrictsbydh = useSelector(
    (state) => state.ditrictsSlice.districtsByDh
  );
  const selectionRows = true;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoding] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [inputs, setInputs] = useState({
    dh_id: "",
    team_id: "",
    district_id:[],
  });
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  }else if (role_id == "3") {
    roleName = "dh";
  }

  const coulmns = [
    { field: "id", headerName: "ID" },
    { field: "code", headerName: "الرمز البريدي", editable: true },
  ];

  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  const handelDhChange = (event) => {
    const { name, value } = event.target;
    setLoding(true);
    setInputs((prevInputs) => ({
      ...prevInputs,
      dh_id: value,
      team_id: "",
      district_id: "",
    }));
    dispatch(getTeamByDh(value)).unwrap()
    .then((data) => {
      setLoding(false);
    })
    dispatch(getDitrictsByDh(value)).unwrap()
    .then((data) => {
      setLoding(false);
    })
  };


  const handelInputChange = (event) => {
    const { name, value } = event.target;
    var districtarr=[];
    districtarr.push(value);
    // console.log(districtarr);
    setInputs((prevInputs) => ({ ...prevInputs, district_id: districtarr }));
  };

  const handelTeamChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, team_id: value }));
  };

  useEffect(() => {
    dispatch(getDhUsers()).unwrap()
    .then((data) => {
      setLoding(false);
    })
  }, [dispatch]);

  const saveData = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoding(true);
      // console.log(inputs);
      dispatch(assignDistrictToTeam(inputs))
        .unwrap()
        .then((data) => {
          setLoding(false);
          toast.success(`تم التوزيع بنجاح`);
          navigate(`/${roleName}/district-list`);
        })
        .catch((error) => {
          setLoding(false);
          toast.error(`${error.message}`);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <>
          <ReturnBtn />
          <div className="form_style">
            <div className="form_header">
              <h5>توزيع منطقه على فريق</h5>
              <p>يرجي ادخال البيانات التالية</p>
            </div>
            <form onSubmit={saveData} action="">
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">رئيس القسم:</label>
                  <select
                    name="dh_id"
                    value={inputs.dh_id}
                    className="form-control"
                    onChange={handelDhChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("dh_id")
                    }
                  >
                    <option value="" selected disabled>
                      اختر
                    </option>
                    {dhusers.map((dh) => (
                      <option key={dh.id} value={dh.id}>
                        {dh.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* <div className="form-group">

                </div> */}

                <div className="error">
                  {simpleValidator.current.message(
                    "dh_id",
                    inputs.dh_id,
                    "required"
                  )}
                </div>
              </div>


              {/* النماطق الخاصه برئيس القسم */}
              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">المنطقه:</label>
                  <select
                    name="district_id"
                    value={inputs.district_id}
                    className="form-control"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("district_id")
                    }
                  >
                    <option value="" selected disabled>
                      اختر
                    </option>
                    {unassignedDistrictsbydh.map((dis) => (
                      <option key={dis.id} value={dis.id}>
                        {dis.code}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "district_id",
                    inputs.district_id,
                    "required"
                  )}
                </div>
              </div>

              <div className="form-group">
                <div className="form-goup-label">
                  <label htmlFor="">الفريق:</label>
                  <select
                    name="team_id"
                    value={inputs.team_id}
                    id=""
                    className="form-control"
                    onChange={handelTeamChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("team_id")
                    }
                  >
                    <option value="" selected disabled>
                      اختر
                    </option>
                    {teamByDh.map((tmhd) => (
                      <option key={tmhd.id} value={tmhd.id}>
                        {tmhd.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "team_id",
                    inputs.team_id,
                    "required"
                  )}
                </div>
              </div>

              {/* <h5 className="titlefor_table">اختر المناطق</h5>
              <DataGridDemo
                selectionRows={selectionRows}
                coulmns={coulmns}
                rows={unassignedDistrictsbydh}
                handleRowSelection={handleRowSelection}
              /> */}

              <div className="btn_submit">
                <button className="btn_style" type="submit">
                  توزيع
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default AssignDistrict;
