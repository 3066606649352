import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import { getNotifications } from "../store/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
import { toast } from "react-toastify";
import NotificationComp from "./NotificationComp";
import { Link } from "react-router-dom";

function Topheader() {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notificationsSlice.notifications
  );
  const [loading, setLoading] = useState(false);
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "4") {
    roleName = "tm";
  } else if (role_id == "6") {
    roleName = "monitor";
  }

  const [notinumber, setnotinumber] = useState(0);

  const showhide = () => {
    document
      .querySelector(".notification_component")
      .classList.toggle("notification_component_visible");
    document
      .querySelector(".backdropmenu")
      .classList.add("backdropmenu_active");
  };

  // const retreiveNotifications = () => {
  //   setLoading(true);
  //   dispatch(getNotifications())
  //     .unwrap()
  //     .then((data) => {
  //       setLoading(false);
  //       setnotinumber(data.data.length);
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //       setLoading(false);
  //     });
  // };

  return (
    <>
      {loading ? (
        <Loading open={true} />
      ) : (
        <div className="top_header">
          <Container maxWidth="xxl">
            <Grid container spacing={2} className="algin-center">
              <Grid xs={3}>
                <div className="logo">
                  <a href="">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                      alt=""
                    />
                  </a>
                </div>
              </Grid>
              <Grid xs={6}>
                <form action="" className="search_input">
                  <div className="form-group">
                    <i className="fas fa-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ابحث"
                    />
                  </div>
                </form>
              </Grid>
              <Grid xs={3}>
                <div className="notifications">
                  <Link to={`/${roleName}/notifications`}>
                    <i className="fas fa-bell"></i>
                    {notinumber == 0 ? (
                      ""
                    ) : (
                      <div className="notification_number">{notinumber}</div>
                    )}
                  </Link>
                  {/* <NotificationComp notifications={notifications} /> */}
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}

export default Topheader;
